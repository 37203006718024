import React from "react"
import Hero from "../layouts/hero"
// import { Link } from "gatsby";

import Layout from "../layouts/layout"
// import Image from "../components/image";
import SEO from "../components/seo"
import { css } from "styled-components"

const BlogPage = () => (
  <>
    <Layout>
      <SEO title="Blog" />
      <div
        css={css`
          margin-top: 0;
        `}
      >
        <Hero />
      </div>
    </Layout>
  </>
)

export default BlogPage
