import React from "react"
import styled, {css} from "styled-components"
import { Link, graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
const ImageBackground = styled(BackgroundImage)`
  background-position: top 20% center;
  margin-top: 0;
  background-size: cover;
  height: 50vh;
  /* override the default margin for sibling elements  */
  + * {
    margin-top: 0;
  }
`
const TextBox = styled("div")`
  background-image: linear-gradient(to top, #63c46e 2rem, #63c46e00);
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 0;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  @media (min-width: calc(550px + 10vw)) {
    padding-left: calc((100vw - 550px) / 2);
    padding-right: calc((100vw - 550px) / 2);
  }
  h1 {
    text-shadow: 1px 1px 3px #eeddff66;
    font-size: 2.25rem;
  }
  p,
  a {
    color: #222;
    margin-top: 0;
  }
  a {
    margin-top: 0.5rem;
  }
`
const Hero = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "thom-milkovic-seattle-wa.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <ImageBackground Tag="section" fluid={image.sharp.fluid} fadeIn="soft">
      <TextBox>
        <div
          css={css`
            max-width: 1170px;
            margin: 0 auto;
            padding: 0 16px;
            width: 100%;
          `}
        >
          <h1>Team Slopes + Gastby &hearts;</h1>
          <p>
            Hello Washington <Link to="/about">Learn abou us &rarr;</Link>
          </p>
        </div>
      </TextBox>
    </ImageBackground>
  )
}

export default Hero
